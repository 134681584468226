(function() {
    'use strict';

    angular.module('ionicFeature', [
        'ionicAlert',
        'ionicData',
        'ionicLogin',
        'ionicOrderedList',
        'ionicRequest',
        'uabDefaultVariable'
    ]);
})();
(function() {
    'use strict';

    angular.module('ionicFeature').service('FeatureEditService', FeatureEditService);

    FeatureEditService.$inject = [
        'DefaultVariableService',
        'FeatureListService',
        'FeatureUserTypeEditService',
        'LoginService'
    ];

    function FeatureEditService(
        DefaultVariableService,
        FeatureListService,
        FeatureUserTypeEditService,
        LoginService
    ) {
        var FeatureEditService = this;

        FeatureEditService.editFeatures = editFeatures;
        function editFeatures(features, userTypeId) {
            if (userTypeId === 0) {
                return LoginService.getActiveUser().then(
                    function(activeUser) {
                        if (activeUser) {
                            userTypeId = DefaultVariableService.getInteger(
                                activeUser.user_type_id
                            );

                            if (userTypeId !== 0) {
                                return FeatureEditService.editFeatures(
                                    features,
                                    userTypeId
                                );
                            }
                        }

                        return false;
                    }
                );
            } else {
                var featuresLength = features.length;

                for (var i = 0; i < featuresLength; i++) {
                    var featuresUserTypes = DefaultVariableService.getArray(
                        features[i].features_user_types
                    );

                    features[i].enabled = DefaultVariableService.get(
                        features[i].enabled,
                        false
                    );

                    if (!features[i].enabled) {
                        if (featuresUserTypes.length !== 0) {
                            FeatureUserTypeEditService.deleteFeatureUserType(featuresUserTypes[0]);
                        }
                    } else if (features[i].enabled) {
                        if (featuresUserTypes.length === 0) {
                            FeatureUserTypeEditService.createFeatureUserType(features[i].id, i, userTypeId);
                        } else if (featuresUserTypes.length !== 0) {
                            if (featuresUserTypes[0].position !== i) {
                                featuresUserTypes[0].position = i;

                                FeatureUserTypeEditService.editFeatureUserType(featuresUserTypes[0]);
                            }
                        }
                    }
                }
            }

            return ListManager.success(features);
        }

        FeatureEditService.getFeatures = getFeatures;
        function getFeatures() {
            return FeatureListService.getFeatures();
        }

        FeatureEditService.reset = reset;
        function reset() {

        }

        var ListManager = FeatureListService.getListManager();

        FeatureEditService.reset();

        return FeatureEditService;
    }
})();
(function() {
    'use strict';

    angular.module('ionicFeature').service(
        'FeatureListService',
        FeatureListService
    );

    FeatureListService.$inject = [
        'DefaultVariableService',
        'ListManagementService',
        'LoginService',
        'RequestManagementService'
    ];

    function FeatureListService(
        DefaultVariableService,
        ListManagementService,
        LoginService,
        RequestManagementService
    ) {
        var FeatureListService = this;

        FeatureListService.getFeatures = getFeatures;
        function getFeatures(userType) {
            var id = 0;
            if (DefaultVariableService.isDefined(userType)) {
                id = DefaultVariableService.getInteger(
                    userType.id,
                    id
                );
            }

            if (id === 0) {
                return LoginService.getActiveUser().then(
                    function (activeUser) {
                        if (activeUser) {
                            return FeatureListService.getFeatures(
                                {
                                    id: activeUser.user_type_id
                                }
                            );
                        }

                        return false;
                    }
                );
            } else {
                return FeatureListService.loadFeatures(userType.id).then(
                    function(data) {
                        if (data) {
                            return FeatureListService.setFeatures(data);
                        }

                        return false;
                    }
                );
            }
        }

        FeatureListService.getListManager = getListManager;
        function getListManager() {
            return ListManager;
        }

        FeatureListService.hasFeature = hasFeature;
        function hasFeature(key, userType) {
            if (DefaultVariableService.isObject(userType)) {
                return FeatureListService.getFeatures(userType).then(
                    function (data) {
                        if (data) {
                            key = key.toLowerCase();

                            var features = DefaultVariableService.getArray(
                                data.features
                            );

                            var featuresLength = features.length;

                            for (var i = 0; i < featuresLength; i++) {
                                var feature = features[i];
                                if (feature.key.toLowerCase() === key) {
                                    return true;
                                } else if (feature.name.toLowerCase() === key) {
                                    return true;
                                }
                            }

                            return false;
                        }
                    }
                );
            } else {
                return LoginService.getActiveUser().then(
                    function(activeUser) {
                        if (activeUser) {
                            var userType = DefaultVariableService.getObject(
                                activeUser.user_type
                            );

                            if (userType.id === 0) {
                                userType.id = activeUser.user_type_id;
                            }

                            if (userType.id !== 0) {
                                return FeatureListService.hasFeature(
                                    key,
                                    userType
                                );
                            }
                        }

                        return activeUser;
                    }
                );
            }
        }

        FeatureListService.loadFeatures = loadFeatures;
        function loadFeatures(userTypeId) {
            var options = RequestManagementService.getRequest();

            options = RequestManagementService.setModel(options, 'features');
            options = RequestManagementService.setAction(options, 'get');

            options = RequestManagementService.setParams(
                options,
                [
                    'FeaturesUserTypes.user_type_id=' + userTypeId
                ]
            );

            options = RequestManagementService.setOrder(
                options,
                [
                    'FeaturesUserTypes.position',
                    'name'
                ]
            );

            options = RequestManagementService.setLimit(options, 100);

            return ListManager.get(options);
        }

        FeatureListService.reset = reset;
        function reset() {
            ListManager.reset();
        }

        FeatureListService.setFeatures = setFeatures;
        function setFeatures(data) {
            var features = DefaultVariableService.getArray(
                data.features
            );

            var featuresLength = features.length;
            for (var i = 0; i < featuresLength; i++) {
                features[i].enabled = DefaultVariableService.getArray(
                    features[i].features_user_types
                ).length !== 0;
            }

            data.features = features;

            return data;
        }

        var ListManager = ListManagementService.getListManager(
            FeatureListService.getFeatures
        );

        FeatureListService.reset();

        return FeatureListService;
    }
})();
(function() {
    'use strict';

    angular.module('ionicFeature').service(
        'FeatureUserTypeEditService',
        FeatureUserTypeEditService
    );

    FeatureUserTypeEditService.$inject = [
        'DataManagementService',
        'DefaultVariableService',
        'FeatureListService',
        'LoginService',
        'RequestManagementService'
    ];

    function FeatureUserTypeEditService(
        DataManagementService,
        DefaultVariableService,
        FeatureListService,
        LoginService,
        RequestManagementService
    ) {
        var FeatureUserTypeEditService = this;

        FeatureUserTypeEditService.createFeatureUserType = createFeatureUserType;
        function createFeatureUserType(featureId, position, userTypeId) {
            var features_user_types = {
                feature_id:   featureId,
                position:     position,
                user_type_id: userTypeId
            };

            var options = RequestManagementService.getRequest();

            options = RequestManagementService.setModel(options, 'features_user_types');
            options = RequestManagementService.setAction(options, 'add');

            options = RequestManagementService.setData(options, features_user_types);

            return DataManagementService.request(options);
        }

        FeatureUserTypeEditService.deleteFeatureUserType = deleteFeatureUserType;
        function deleteFeatureUserType(featureUserType) {
            var options = RequestManagementService.getRequest();

            options = RequestManagementService.setModel(options, 'features_user_types');
            options = RequestManagementService.setAction(options, 'delete', featureUserType.id);

            return DataManagementService.request(options);
        }

        FeatureUserTypeEditService.editFeatureUserType = editFeatureUserType;
        function editFeatureUserType(featureUserType) {
            var options = RequestManagementService.getRequest();

            options = RequestManagementService.setModel(options, 'features_user_types');
            options = RequestManagementService.setAction(options, 'edit', featureUserType.id);

            options = RequestManagementService.setData(options, featureUserType);

            return DataManagementService.request(options);
        }

        FeatureUserTypeEditService.reset = reset;
        function reset() {

        }

        FeatureUserTypeEditService.reset();

        return FeatureUserTypeEditService;
    }
})();
(function() {
    'use strict';

    angular.module('ionicFeature').controller(
        'FeatureUserTypeEditController',
        FeatureUserTypeEditController
    );

    FeatureUserTypeEditController.$inject = [
        'AlertService',
        'DefaultVariableService',
        'FeatureUserTypeEditService',
        'LoginService',
        '$scope'
    ];

    function FeatureUserTypeEditController(
        AlertService,
        DefaultVariableService,
        FeatureUserTypeEditService,
        LoginService,
        $scope
    ) {
        var FeatureUserTypeEditController = this;

        FeatureUserTypeEditController.featureUserType = DefaultVariableService.getObject();
        FeatureUserTypeEditController.featureUserType.name = null;

        $scope.$watch(
            'onCancel',
            function(onCancel) {
                FeatureUserTypeEditController.onCancel = DefaultVariableService.getPromise(
                    onCancel
                );
            }
        );

        $scope.$watch(
            'onEdit',
            function(onEdit) {
                FeatureUserTypeEditController.onEdit = DefaultVariableService.getPromise(
                    onEdit
                );
            }
        );

        $scope.$watch(
            'feature',
            function(feature) {
                FeatureUserTypeEditController.feature = DefaultVariableService.getObject(
                    feature
                );

                if (FeatureUserTypeEditController.featureUserType.name === null) {
                    FeatureUserTypeEditController.featureUserType.name = FeatureUserTypeEditController.feature.name;
                }
            }
        );

        $scope.$watch(
            'featureUserType',
            function(featureUserType) {
                FeatureUserTypeEditController.featureUserType = DefaultVariableService.getObject(
                    featureUserType
                );

                if (FeatureUserTypeEditController.featureUserType.name === null) {
                    FeatureUserTypeEditController.featureUserType.name = FeatureUserTypeEditController.feature.preferred_name;
                }
            }
        );

        FeatureUserTypeEditController.editFeatureUserType = editFeatureUserType;
        function editFeatureUserType() {
            FeatureUserTypeEditController.isEditingFeatureUserType = true;

            return FeatureUserTypeEditService.editFeatureUserType(
                FeatureUserTypeEditController.featureUserType
            ).then(
                function(data) {
                    if (data) {
                        AlertService.addMessage(
                            'FEATURE USER TYPE EDITED.'
                        );

                        FeatureUserTypeEditController.feature.preferred_name = FeatureUserTypeEditController.featureUserType.name;

                        FeatureUserTypeEditController.onEdit(data);
                    }

                    return data;
                }
            ).finally(
                function() {
                    FeatureUserTypeEditController.isEditingFeatureUserType = false;
                }
            );
        }

        FeatureUserTypeEditController.reset = reset;
        function reset() {
            FeatureUserTypeEditController.isEditingFeatureUserType = false;
        }

        FeatureUserTypeEditController.init = init;
        function init() {
            FeatureUserTypeEditController.reset();
        }

        LoginService.register(FeatureUserTypeEditController);
    }
})();

(function() {
    'use strict';

    angular.module('ionicFeature').directive('ionicFeatureUserTypeEdit', ionicFeatureUserTypeEdit);

    function ionicFeatureUserTypeEdit() {
        return {
            controller:   'FeatureUserTypeEditController',
            controllerAs: 'ctrl',
            restrict:     'E',
            scope: {
                featureUserType: '=',
                feature:         '=',
                onCancel:        '=',
                onEdit:          '='
            },
            template:'<ion-list><label class="item item-input item-floating-label"><span class="input-label">NAME</span> <input type="text" placeholder="NAME" data-ng-model="ctrl.featureUserType.name"></label><div class="row"><button class="button button-full accept-button col-xl-6" data-ng-click="ctrl.editFeatureUserType()" data-ng-disabled="ctrl.isEditingFeatureUserType">{{ ctrl.isEditingFeatureUserType ? \'EDITING...\' : \'EDIT\' }}</button> <button class="button button-full decline-button col-xl-6" data-ng-click="ctrl.cancel()">CANCEL</button></div></ion-list>'
        };
    }
})();
(function() {
    'use strict';

    angular.module('ionicFeature').controller(
        'FeatureListController',
        FeatureListController
    );

    FeatureListController.$inject = [
        'AlertService',
        'DefaultVariableService',
        'LoginService',
        'FeatureEditService',
        'FeatureListService',
        '$scope'
    ];

    function FeatureListController(
        AlertService,
        DefaultVariableService,
        LoginService,
        FeatureEditService,
        FeatureListService,
        $scope
    ) {
        var FeatureListController = this;

        $scope.$watch(
            'onCancel',
            function(onCancel) {
                FeatureListController.onCancel = DefaultVariableService.getPromise(
                    onCancel
                );
            }
        );

        $scope.$watch(
            'userType',
            function(userType) {
                FeatureListController.userType = DefaultVariableService.getObject(
                    userType
                );

                FeatureListController.getFeatures(
                    FeatureListController.userType
                );
            }
        );

        FeatureListController.getFeatures = getFeatures;
        function getFeatures(userType) {
            FeatureListController.isLoadingFeatures = true;

            return FeatureListService.getFeatures(userType).then(
                function(data) {
                    if (data) {
                        FeatureListController.features = DefaultVariableService.getArray(
                            data.features
                        );
                    }

                    return data;
                }
            ).finally(
                function() {
                    FeatureListController.isLoadingFeatures = false;
                }
            );
        }

        FeatureListController.saveFeatures = saveFeatures;
        function saveFeatures(features) {
            FeatureListController.isSavingFeatures = true;

            FeatureListController.features = DefaultVariableService.getArray(
                features,
                FeatureListController.features
            );

            FeatureListController.userType.features = FeatureListController.features;

            return FeatureEditService.editFeatures(
                FeatureListController.features,
                FeatureListController.userType.id
            ).then(
                function(response) {
                    if (response) {
                        AlertService.addMessage('FEATURES SAVED.');
                    }

                    return response;
                }
            ).finally(
                function() {
                    FeatureListController.isSavingFeatures = false;
                }
            );
        }

        FeatureListController.selectFeature = selectFeature;
        function selectFeature(feature) {
            FeatureListController.feature = DefaultVariableService.getObject(
                feature
            );

            FeatureListController.feature.features_user_types = DefaultVariableService.getArray(
                FeatureListController.feature.features_user_types
            );

            if (FeatureListController.feature.features_user_types.length !== 0) {
                FeatureListController.featureUserType = DefaultVariableService.getObject(
                    FeatureListController.feature.features_user_types[0]
                );
            }
        }

        FeatureListController.reset = reset;
        function reset() {
            FeatureListController.isSavingFeatures = false;

            FeatureListController.resetFeature();

            FeatureListController.features = [];

            FeatureListController.userType = DefaultVariableService.getObject();
        }

        FeatureListController.resetFeature = resetFeature;
        function resetFeature() {
            FeatureListController.feature = DefaultVariableService.getObject();

            FeatureListController.featureUserType = DefaultVariableService.getObject();
        }

        FeatureListController.init = init;
        function init() {
            FeatureListController.reset();
        }

        LoginService.register(FeatureListController);
    }
})();

(function() {
    'use strict';

    angular.module('ionicFeature').directive('ionicFeatureList', ionicFeatureList);

    function ionicFeatureList() {
        return {
            controller:   'FeatureListController',
            controllerAs: 'ctrl',
            restrict:     'E',
            scope: {
                onCancel: '=',
                userType: '='
            },
            template:'<div data-ng-if="!ctrl.isLoadingFeatures"><ionic-ordered-list data-ng-if="ctrl.feature.id === 0" allow-disable="true" display-field="name" objects="ctrl.features" on-cancel="ctrl.onCancel" on-edit="ctrl.saveFeatures" on-select="ctrl.selectFeature"></ionic-ordered-list><ionic-feature-user-type-edit data-ng-if="ctrl.feature.id !== 0" feature="ctrl.feature" feature-user-type="ctrl.featureUserType" on-cancel="ctrl.selectFeature" on-edit="ctrl.resetFeature"></ionic-feature-user-type-edit></div><span data-ng-if="ctrl.isLoadingFeatures"><ion-spinner class="centerSpinner"></ion-spinner></span>'
        };
    }
})();